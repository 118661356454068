// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '6bff1b9b8e2b3d46e7926d93e54ef0a0';
const css =`._valueRef_sxdqx_1{display:block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}._valueRef_sxdqx_1 img,._valueRef_sxdqx_1 svg{display:inline-block;margin-top:-.1rem;max-height:1rem;vertical-align:middle}._valueRef_sxdqx_1 img{margin-right:-.125rem}._valueRefFlex_sxdqx_1{display:inline-flex;justify-content:center}._name_sxdqx_1{white-space:nowrap}._valueRefTruncate_sxdqx_1{align-items:center;display:flex;flex-direction:row;flex-wrap:nowrap}._truncatedSegment_sxdqx_1{display:inline-block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}._visibleSegment_sxdqx_1{flex-grow:0;flex-shrink:0}._tagIcon_sxdqx_1{display:inline-block;height:1rem;margin-right:.25rem;width:1rem}._caseContainer_sxdqx_1{margin-top:.5rem}._caseContainer_sxdqx_1 ._field_sxdqx_1{position:relative}._caseContainer_sxdqx_1 ._field_sxdqx_1 ._deleteButton_sxdqx_1{margin-top:-.625rem;position:absolute;right:0;top:50%}`;
const styles = {
    get ['valueRef']() { injectStyles(key, css, options);  return '_valueRef_sxdqx_1'; },
    get ['valueRefFlex']() { injectStyles(key, css, options);  return '_valueRefFlex_sxdqx_1'; },
    get ['name']() { injectStyles(key, css, options);  return '_name_sxdqx_1'; },
    get ['valueRefTruncate']() { injectStyles(key, css, options);  return '_valueRefTruncate_sxdqx_1'; },
    get ['truncatedSegment']() { injectStyles(key, css, options);  return '_truncatedSegment_sxdqx_1'; },
    get ['visibleSegment']() { injectStyles(key, css, options);  return '_visibleSegment_sxdqx_1'; },
    get ['tagIcon']() { injectStyles(key, css, options);  return '_tagIcon_sxdqx_1'; },
    get ['caseContainer']() { injectStyles(key, css, options);  return '_caseContainer_sxdqx_1'; },
    get ['field']() { injectStyles(key, css, options);  return '_field_sxdqx_1'; },
    get ['deleteButton']() { injectStyles(key, css, options);  return '_deleteButton_sxdqx_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
